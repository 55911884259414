import { Injectable } from '@angular/core';
import { 
  faEdit,
  faPlus,
  faTimes,
  faSave,
  faBath,
  faBed,
  faCar,
  faSort,
  faSortUp,
  faHammer,
  faSortDown,
  faFilePdf,
  faTrashAlt,
  faCircleNotch,
  faCalculator,
  faCalendarAlt,
  faCashRegister,
  faCreditCard,
  faHelmetSafety,
  faCalendar,
  faClock,
  faGasPump,
  faEye,
  faLink,
  faSearch,
  faGauge,
  faGears,
  faSpinner,
  faMoneyBill,
  faPhoneAlt,
  faMailBulk,
  faBuilding,
  faDownload,
  faFileExcel,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faUserShield,
  faEllipsisVertical,
  faTriangleExclamation,
  faMagnifyingGlass,
  faCircleExclamation,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  faTriangleExclamation = faTriangleExclamation;
  faCircleExclamation = faCircleExclamation;
  faEye = faEye;
  faEdit = faEdit;
  faLink = faLink;
  faSearch = faSearch;
  faSave = faSave;
  faBath = faBath;
  faBed = faBed;
  faCar = faCar;
  faSort = faSort;
  faHammer = faHammer;
  faSpinner = faSpinner;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilePdf = faFilePdf;
  faTrashAlt = faTrashAlt;
  faCashRegister = faCashRegister;
  faHelmetSafety = faHelmetSafety;
  faCreditCard = faCreditCard;
  faGasPump = faGasPump;
  faGears = faGears;
  faPlus = faPlus;
  faClock = faClock;
  faTimes = faTimes;
  faGauge = faGauge;
  faFileExcel = faFileExcel;
  faMoneyBill = faMoneyBill;
  faPhoneAlt = faPhoneAlt;
  faMailBulk = faMailBulk;
  faBuilding = faBuilding;
  faDownload = faDownload;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faCalculator = faCalculator;
  faCircleNotch = faCircleNotch;
  faCalendarAlt = faCalendarAlt;
  faUserShield = faUserShield;
  faCalendar = faCalendar;
  faMagnifyingGlass = faMagnifyingGlass;
  faEllipsisVertical = faEllipsisVertical;
  faStar = faStar;
  
  constructor() { }

}
