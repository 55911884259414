import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-modal-tools',
  template: `
    <div #modalTools id="modalTools" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalTools" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Herramientas</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body pt-3 pb-3">
            <div class="list-group shadow-sm" *ngIf="tools.length>0">
              <div class="list-group-item" *ngFor="let tool of tools">
                <div class="form-check form-switch ps-1">
                  <input
                    class="form-check-input float-end"
                    type="checkbox"
                    [checked]="tool.checked"
                    [value]="tool.id"
                    (change)="toggleTool($event)"
                  >
                  <label class="form-check-label" for="flexCheckDefault">
                    {{tool.name}}
                  </label>
                </div>
              </div>              
            </div>
            <div class="list-group shadow-sm" *ngIf="tools.length==0">
              <div class="list-group-item">
                <p class="mb-0 text-center text-muted">No hay herramientas disponibles</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalToolsComponent implements OnInit {
  @Input() technician: any;
  @Input() profile: any;

  tools: any = [];
  asigned_tools: any = [];

  faTimes = faTimes;

  constructor(
    private tasks: TasksService,
    private toastr: ToastrService,
    
  ) { }

  ngOnInit(): void {
    this.loadTools();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['technician'] && changes['technician'].currentValue) {
      this.loadAssignedTools();
    }
  }

  loadTools() {
    console.log('loadTools');
    this.tasks.getTools(this.profile.id).subscribe({
      next: (resp: any) => {
        console.log('TOOLS LOADAD resp ===============> ', resp);
        this.tools = resp.data;
      },
      error: (err: any) => {
        console.log('err => ', err);
      }
    });
  }

  loadAssignedTools() {
    if (!this.technician.id) return;
    this.tasks.getUserTools(this.technician.id).subscribe({
      next: (resp: any) => {
        this.asigned_tools = resp;
        console.log('this.asigned_tools ==> ', this.asigned_tools);
        console.log('this.tools ==> ', this.tools);
        this.asigned_tools.map((tool_asigned: any) => {
          this.tools.forEach((tool: any) => {
            tool.checked = tool.id == tool_asigned.id ? true : false;
          });
        });
      },
      error: (err: any) => {
        console.log('err => ', err);
      }
    });
  }

  toggleTool(tool: any) {
    console.log('tool => ', tool.target.value);
    const toolId = tool.target.value;
    const isChecked = tool.target.checked;
    if (isChecked) {
      this.tasks.addUserTool(toolId, { assignee_id: this.technician.id }).subscribe({
        next: (resp: any) => {
          console.log('resp => ', resp);
          this.toastr.success('Herramienta agregada correctamente', 'Éxito');
        },
        error: (err: any) => {
          console.log('err => ', err);
          this.toastr.error(err.error.message, 'Error');
        }
      });
    } else {
      this.tasks.removeUserTool(toolId).subscribe({
        next: (resp: any) => {
          console.log('resp => ', resp);
          this.toastr.success('Herramienta removida correctamente', 'Éxito');
        },
        error: (err: any) => {
          console.log('err => ', err);
          this.toastr.error(err.error.message, 'Error');
        }
      });
    }
  }
}
