import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import * as bs from 'bootstrap';

@Component({
  selector: 'app-technicians',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Técnicos</h1>
            <p>Configura los técnicos del sistema.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
          <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.config.technicians.write" routerLink="/technicians/create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar técnico
            </button>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-12 mt-5">
            <div class="list-group rounded-4 shadow">
              <div class="list-group-item bg-light" *ngFor="let user of technicians | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <div class="row">
                  <div class="col-md-1 pt-0 text-center">
                    <app-item-tiny-user [user]="user"></app-item-tiny-user>
                  </div>
                  <div class="col-md-8 mt-1">
                    <h4 class="mb-0">
                      {{user.name}} {{user.last_name}}
                    </h4>
                    <p class="mb-0">
                      <span class="ms-2" style="font-size: 12px;">{{user.email}}</span>
                  </div>
                  <div class="col-md-3 text-end pt-1">
                    <button type="button" routerLink="{{user.id}}" class="btn btn-outline-primary" *ngIf="permissions?.modules.config.technicians.write">
                      <fa-icon [icon]="icons.faEdit" size="1x"></fa-icon>
                      Editar
                    </button>
                    <button type="button" class="btn btn-outline-danger ms-2" (click)="removeUser(user.id)">
                      <fa-icon [icon]="icons.faTrashAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          
      </div>
    </div>
    <app-modal-work-schedule [technician]="selectedTechnician" [work_schedule]="work_schedule" [profile]="profile"></app-modal-work-schedule>
    <app-modal-skills [technician]="selectedTechnician" [profile]="profile" (skillData)="dismissSkills()"></app-modal-skills>
    <app-modal-vehicles (vehicleData)="updateVehicle($event)" [technician]="selectedTechnician" [profile]="profile"></app-modal-vehicles>
    <app-modal-tools [technician]="selectedTechnician" [profile]="profile"></app-modal-tools>
  `,
  styleUrls: []
})
export class TechniciansComponent implements OnInit {

  loading: boolean = false;
  technicians: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});
  profile: any = {};
  permissions: any = {};

  selectedTechnician: any = null;
  selectedTasks: any[] = [];
  vehicle: any = {};
  skills: any[] = [];
  asignedSkills: any[] = [];
  tools: any[] = [];
  work_schedule: any = {};

  status: any;
  summary: any = {};

  builders: any[] = [];
  taskCategories: any[] = [];

  payload: any = {
    page: 1,
    per_page: 10,
    role_id: 3
  };

  p: number = 1;

  @ViewChild('modalSkills', { read: ElementRef, static: false }) modalSkills !: ElementRef | undefined;

  constructor(
    private store: SettlementsService,
    private tasks: TasksService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public icons: IconsService,
    private catalog: CatalogsService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit() {    
    this.loadUsers(this.payload);
    // this.status = // ;this.catalog.status[this.selectedTasks.status];
    this.initForm(); 
    this.loadBuilders();
  }

  getStatus(task: any) {
    return this.catalog.status[task.status];
  }

  getBuilder(task: any) {
    return this.builders.find((builder: any) => builder.id == task.builder_id);
  }

  loadBuilders() {
    this.tasks.getBuilders({ page: 1, per_page: 100}).subscribe((cat: any) => {
      console.log('BUILDERS ======> ', cat);
      this.builders = cat.data;
      this.taskCategories = cat.data;
    });  
  }

  loadUsers(payload: any) {
    this.loading = true;
    this.store.getUsers(payload).subscribe({
      next: (technicians: any) => {
        console.log(technicians);
        this.technicians = technicians.data;
        this.meta = technicians.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.technicians = [];
        this.loading = false;
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  searchUsers(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadUsers(this.payload);
  }

  selectTechnician(technician: any) {
    console.log('selected technician => ', technician);
    this.selectedTechnician = technician;
    this.loadTasks(technician.id);
    this.loadAsignedVehicle(technician.id);
    this.loadSkills(technician.id);
    this.loadTools(technician.id);
    this.loadStats(technician.id);
    this.loadWorkSchedule(technician.id);
  }

  loadTasks(id: any) {
    console.log('event => ', id);
    this.tasks.getTasks({assignee_id: id, per_page: 10, page: 1}).subscribe((tasks: any) => {
      console.log('tasks => ', tasks);
      this.selectedTasks = tasks.data;
    });
  }

  loadAsignedVehicle(id: number) {
    this.tasks.getVehicles({ page: 1, per_page: 100, assignee_id: id }).subscribe({
      next: (data: any) => {
        console.log('vehicle => ', data);
        this.vehicle = data.data[0];
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.vehicle = {};
      }
    });
  }

  loadSkills(id: number) {
    this.tasks.getSkills({ user_id: id }).subscribe({
      next: (data: any) => {
        this.skills = data;
      },
      error: (error: any) => {}
    });

    this.tasks.getUserSkills(id).subscribe({
      next: (data: any) => {
        console.log('asignedSkills => ', data);
        this.asignedSkills = data;
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.asignedSkills = [];
      }    
    });
  }

  loadWorkSchedule(id: number) {
    this.tasks.getWorkHour(id).subscribe({
      next: (data: any) => {
        console.log('work_schedule => ', data);
        this.work_schedule = data;
      },
      error: (error: any) => {}
    });
  }

  loadTools(id: number) {
    this.tasks.getTools({ assignee_id: id }).subscribe({
      next: (data: any) => {
        console.log('tools => ', data.data);
        this.tools = data.data;
      },
      error: (error: any) => {}
    });
  }

  loadStats(id: number) {
    this.tasks.getTechStatSummary(id).subscribe((summary: any) => {
      console.log('summary => ', summary);
      this.summary = summary;
    });
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadUsers(this.payload);
  }

  removeUser(id: number) {
    this.store.destroyUser(id).subscribe({
      next: (data: any) => {
        this.toastr.success('Usuario eliminado correctamente.', 'Éxito');
        this.loadUsers(this.payload);
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al eliminar el usuario.', 'Error');        
      },
    });
  }

  updateVehicle(vehicle: any) {
    console.log('vehicle => ', vehicle);
    
    this.tasks.updateAssignedVehicle(vehicle.vehicle_id, vehicle).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Vehículo actualizado correctamente.');
        this.loadAsignedVehicle(this.selectedTechnician.id);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar el vehículo');
      }
    });
  }

  addTools() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalTools')).show();
  }

  addVehicle() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalVehicle')).show();
  }

  addSkills() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalSkills')).show();
  }

  dismissSkills() {
    console.log('DISMISS SKILLS');
    // new bs.Modal(<HTMLInputElement>document.getElementById('modalSkills')).hide();
    const modalElement = this.modalSkills!.nativeElement;
    const modalInstance = new bs.Modal(modalElement);
    modalInstance.hide();
  }

}
