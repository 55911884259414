import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { CsvService } from 'src/app/shared/services/csv.service';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-invoice-uploader',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-4">
        
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Generación de facturas</h1>
            <p>Carga masiva de facturas ingreso.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar factura
            </!-->
            <input
              #fileUploadSimple
              [accept]="'.csv'"
              type="file"
              class="file-input"
              (change)="importDataFromCSV($event)"
              hidden="true"
            />
            <button class="btn btn-outline-primary btn-lg rounded-5 border border-2 border-primary shadow mt-2" (click)="fileUploadSimple.click()">Cargar archivo</button>
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card rounded-5 shadow">
              <div class="card-body p-3" *ngIf="importedData">
              <div *ngIf="importedData.length>0" class="text-end">
                <button class="btn btn-warning btn-lg rounded-5" (click)="generate()" [disabled]="generating">Generar Facturas</button>
              </div>
                <table class="table table-striped table-sm">
                <!-- rfc_emisor
                rfc_receptor
                razon_social_receptor
                uso_cfdi
                regimen_receptor
                cp_receptor
                calle_receptor
                num_ext_receptor
                num_int_receptor
                colonia_receptor
                cantidad_concepto
                serie_concepto
                codigo_unidad_concepto
                precio_unitario_concepto
                importe_concepto
                objeto_impuesto
                iva_concepto
                subtotal
                iva	
                total -->
                  <thead>
                    <tr>
                      <th>RFC Emisor</th>
                      <th>RFC Receptor</th>
                      <th>Razon social</th>
                      <th>USO CFDI</th>
                      <th>Regimen</th>
                      <th>CP</th>
                      <th>Subtotal</th>
                      <th>IVA</th>
                      <th>Total</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let invoice of importedData">
                      <td>{{ invoice.rfc_emisor }}</td>
                      <td>{{ invoice.rfc_receptor }}</td>                      
                      <td>{{ invoice.razon_social_receptor }}</td>
                      <td>{{ invoice.uso_cfdi }}</td>
                      <td>{{ invoice.regimen_receptor }}</td>
                      <td>{{ invoice.cp_receptor }}</td>
                      <td>{{ invoice.subtotal | currency }}</td>
                      <td>{{ invoice.iva | currency }}</td>
                      <td>{{ invoice.total | currency }}</td>
                      <td>
                        <div *ngIf="invoice.uuid">
                          <button class="btn btn-link" (click)="downloadInvoice(invoice)">Descargar</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

  `,
  styleUrls: []
})
export class InvoiceUploaderComponent {
  importedData: any;
  loading: boolean = false;
  profile: any;
  generating: boolean = false;

  complemento: any = {};

  constructor(
    private _csvService: CsvService,
    private store: SettlementsService,
    private toastr: ToastrService,
    public images: ImageService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    
  }

  ngOnInit(): void {
    this.loadLogo();
  }

  async loadLogo() {
    const img = this.images.buildURL(this.profile.logo);
    const imx: any = await this.images.getBase64ImageFromUrl(img);
    this.profile.logo = `${imx.split(',')[1]}`;
  }

  private async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    let fileContent = await file.text();
    
    return fileContent;
  }

  public async importDataFromCSV(event: any) {
    let fileContent = await this.getTextFromFile(event);
    this.importedData = this._csvService.importDataFromCSV(fileContent);
  }

  loadProfile() {}

  generate() {
    this.loading = false;
    this.generating = true;
    this.importedData.map((invoice: any) => {
      console.log(invoice);

      let dueDate = new Date();
      dueDate.setDate(dueDate.getDate());
      dueDate.toISOString().substring(0, 10);

      const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      const tn = tzoffset + 3600000;
      const localISOTime = (new Date(Date.now() - tn)).toISOString();
      console.log("localISOTime => ", localISOTime);

      let concepto: any = [{
        Cantidad: '1',
        Serie: '0000012345',
        CodigoUnidad: invoice.codigo_unidad_concepto,
        CodigoProducto: invoice.Clave_SAT,
        Producto: invoice.importe_concepto,
        PrecioUnitario: invoice.subtotal,
        Importe: invoice.subtotal,
        ObjetoDeImpuesto: '02',
        Impuestos: [
          {
            TipoImpuesto: '1',
            Impuesto: '2',
            Factor: '1',
            Base: invoice.subtotal,
            Tasa: '0.160000',
            ImpuestoImporte: invoice.subtotal*0.16
          }
        ]
      }];
      
      let encabezados: any = {
        CFDIsRelacionados: "",
        TipoRelacion: "04",
        Receptor:{
          RFC: invoice.rfc_receptor,
          NombreRazonSocial: invoice.razon_social_receptor,
          UsoCFDI: invoice.uso_cfdi,
          RegimenFiscal: invoice.regimen_receptor,
          Direccion: {
            Calle: invoice.calle_receptor,
            NumeroExterior: invoice.num_ext_receptor,
            NumeroInterior: invoice.num_int_receptor,
            Colonia: invoice.colonia_receptor,
            Localidad: "",
            Municipio: "",
            Estado: "",
            Pais: "",
            CodigoPostal: invoice.cp_receptor
          }
        },
        Fecha: localISOTime.slice(0,19),
        Serie: invoice.serie_concepto.toString(),
        Folio: invoice.folio.toString(),
        MetodoPago: invoice.metodo_pago,
        FormaPago: invoice.forma_pago,
        Moneda: "MXN",
        LugarExpedicion: this.profile.zip_code,
        SubTotal: invoice.subtotal,
        Total: invoice.total,
      };

      let complemento: any = {        
        formaPago: invoice.forma_pago,
        serie: invoice.serie_concepto.toString(),
        folio: invoice.folio.toString(),
        usoCFDI: invoice.uso_cfdi,
        metodoPago: invoice.metodo_pago,
      };

      let payload: any = {
        profile: this.profile,
        Encabezado: encabezados,
        Conceptos: concepto,
        complemento: complemento,
        utilityData: this.profile,
      };

      // if (this.invoiceForm.invalid) {
        // this.toastr.error('Completa toda la información requerida del formulario.', 'Error');
        // this.loading = false;
        // this.invoiceForm.markAllAsTouched();
        // return;
      // }

      console.log('PAYLOAD INVOICE ===> ', payload);

      this.store.createGenericInvoice(payload).subscribe({
        next: (data: any) => {
          console.log("DATA =====> ", data);
          this.loading = false;
          
          invoice.idVersionTimbrado = data.idVersionTimbrado;
          invoice.fecha = data.fecha;
          invoice.cadenaOriginal = data.cadenaOriginal;
          invoice.cadenaOriginalCFD = data.cadenaOriginalCFD;
          invoice.noCertificado = data.noCertificado;
          invoice.rfcProvCertif = data.rfcProvCertif;
          invoice.selloCFD = data.selloCFD;
          invoice.selloSAT = data.selloSAT;
          invoice.timbreXML = data.timbreXML;
          invoice.uuid = data.uuid;
          invoice.cfdixml = data.cfdixml;
          invoice.uuid = data.uuid;
          // invoice.serie = data.respuesta["serie_concepto"];
          // invoice.created_at = data.respuesta.created_at;
          // invoice.updated_at = data.respuesta.updated_at;
          // invoice.formaPago = data.respuesta["forma_pago"];
          invoice.usoCFDI = data.usoCFDI;          
          invoice.tipo = data.tipo;
          invoice.related_invoice = data.related_invoice;
          invoice.estatus = data.estatus;        
          invoice.conceptos = data.conceptos;

          console.log('INVOICE ===> ', invoice);

          this.downloadInvoice(invoice);
          this.toastr.success('Factura creada correctamente.', 'Éxito');
        },
        error: (err: any) => {
          console.log(err);
          if (err.status >= 500) {
            this.toastr.error('Ocurrió un error al crear la factura.', 'Error');
          }
          this.loading = false;
        }
      });  
    });
    
  }

  downloadInvoice(invoice: any) {
    this.store.downloadGenericInvoice({
      invoiceData: invoice,
      conceptos: invoice.conceptos,
      utilityData: this.profile
    }).subscribe({
      next: (data: any) => {
        console.log(data);
        this.loading = false;

        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link=document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        const blob2 = new Blob([invoice.cfdixml], { type: 'application/xml' });

        var fileURL2 = window.URL.createObjectURL(blob2);
        var link2=document.createElement('a');

        link2.href = fileURL2;
        link2.download = `${invoice.serie}_${invoice.folio}.xml`;
        link2.click();

        this.toastr.success('Factura descargada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        console.log(err);
        if (err.status >= 500) {
          this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
        }
        this.loading = false;
      }
    });
  }
}
