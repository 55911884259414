import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faPlus, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import * as bs from 'bootstrap';

@Component({
  selector: 'app-invoice-creditnote',
  template: `
     <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-4">
        
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Notas de crédito</h1>
            <p>Administra las notas de crédito.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar factura
            </!-->
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <app-filter-full
          [profile]="profile"
          [toDate]="toDate"
          [fromDate]="fromDate"
          (billsSettlement)="loadInvoicesBySettlement($event)"
          (billsBuilding)="loadInvoicesByBuilding($event)"
          (billsFloor)="loadInvoicesByFloor($event)"
          (billsUnit)="loadInvoicesByUnit($event)"
          (billsSort)="loadInvoicesSort($event)"
          (billsOrder)="loadInvoicesOrder($event)"
          (dateRange)="updateDate($event)"
          (clear)="clearFilter()"
        ></app-filter-full>
        <div class="row mb-4">
          <div class="col-md-12 mt-5 pb-3">
            <div class="list-group rounded-4 shadow" *ngIf="invoices.length>0">
              <div class="list-group-item bg-light px-3 py-3" *ngFor="let invoice of invoices | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <app-item-invoice-nota [invoice]="invoice" [profile]="profile" (invoiceSelect)="selectInvoice($event)"></app-item-invoice-nota>                    
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="invoices.length==0">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="invoices.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
            ></pagination-controls>
          </div>
        </div>
        <app-modal-invoice-cancel [invoice]="selectedInvoice" (invoiceData)="cancelInvoice($event)"></app-modal-invoice-cancel>
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceCreditnoteComponent {
  loading: boolean = false;
  invoices: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  searchBox: FormGroup = new FormGroup({});

  selectedInvoice: any = null;

  payload: any = {
    page: 1,
    per_page: 10,
    tipo: 3,
    created_at_since: '',
    created_at_to: '',
    sort: 'created_at',
    order: 'DESC'
  };

  p: number = 1;

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile = this.profile.id;

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);
    //
    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
  }
  

  ngOnInit(): void {
    this.loadInvoices(this.payload);
    this.breadcrumbService.set('@creditNote', 'Notas de crédito');
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadInvoices(this.payload);
    }
  }

  selectInvoice(invoice: any) {
    console.log('RECEIVED INVOICE ==> ', invoice);
    new bs.Modal(<HTMLInputElement>document.getElementById('modalDeleteInvoice')).show();
  }

  cancelInvoice(event: any) {    
    this.store.cancelInvoice({
      profile: this.profile,
      id: this.selectedInvoice.id,
      reason: event
    }).subscribe({
      next: (res: any) => {
        console.log(res);
        this.toastr.success('Factura cancelada correctamente.', 'Éxito');
        // this.router.navigate(['/invoices/canceladas']);
        window.location.reload();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al cancelar la factura.', 'Error');
      }
    });
  }

  loadInvoices(payload: any) {
    this.loading = true;
    this.store.getInvoicesQuery(payload).subscribe({
      next: (invoices: any) => {
        this.invoices = invoices.data;
        this.meta = invoices.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.invoices = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las amenidades.', 'Error');
        }
      }
    });
  }

  loadInvoicesBySettlement(settlement_id: any) {
    this.store.getInvoiceBySettlement(settlement_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByBuilding(building_id: any) {
    this.store.getInvoiceByBuilding(building_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByFloor(floor_id: any) {
    this.store.getInvoiceByFloor(floor_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesByUnit(unit_id: any) {
    this.store.getInvoiceByUnit(unit_id, this.payload).subscribe((data: any) => {
      this.invoices = data.data;
      this.meta = data.meta;
    });
  }

  loadInvoicesSort(event: any) {
    console.log('SORT EVENT ==> ', event);
    this.payload.sort = event;
    // this.payload.order = event.order;
    // this.loadBills(this.payload);
    this.getCurrentFilter();
  }

  loadInvoicesOrder(event: any) {
    console.log('ORDER EVENT ==> ', event);
    this.payload.order = event;
    this.getCurrentFilter();
  }

  getCurrentFilter() {
    console.log('CURRENT FILTER ==> ', this.payload);
    let count = 0;
    this.route.queryParamMap.subscribe(params => {      
      if (count>0){return;}
      console.log('PARAMS ==> ', params);

      if (params.has('unidad')) {
        this.payload.unit_id = params.get('unidad');
        this.loadInvoicesByUnit(this.payload.unit_id);
        return;
      }

      if (params.has('piso')) {
        this.payload.floor_id = params.get('piso');
        this.loadInvoicesByFloor(this.payload.floor_id);
        return;
      }

      if (params.has('edificio')) {
        this.payload.building_id = params.get('edificio');
        this.loadInvoicesByBuilding(this.payload.building_id);
        return;
      }

      if (params.has('condominio')) {
        this.payload.settlement_id = params.get('condominio');
        this.loadInvoicesBySettlement(this.payload.settlement_id);
        return;
      }
      count++;
    });
  }

  clearFilter() {
    this.loadInvoices(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadInvoices(this.payload);
  }

  tag(status: any) {
    if (status === 'active') {
      return 'Activo';
    } else {
      return 'Inactivo';
    }
  }
}
