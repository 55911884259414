import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { environment } from 'src/environments/environment';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-modal-invoice-nota',
  template: `
    <div #modalInvoiceComplement id="modalInvoiceNota" class="modal fade" tabindex="-1" aria-labelledby="modalInvoiceNota" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">Nota de crédito</div>
          </div>
          <div class="modal-body p-4">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm mb-3">
                  <thead>
                    <tr>
                      <th>Cantidad</th>
                      <th>Clave</th>
                      <th>Importe</th>
                      <th>Valor Unitario</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let concepto of conceptos">
                      <td>{{concepto['$']['Cantidad']}}</td>
                      <td>{{concepto['$']['ClaveProdServ']}}</td>
                      <td>{{concepto['$']['Importe']}}</td>
                      <td>{{concepto['$']['ValorUnitario']}}</td>
                    </tr>
                  </tbody>
                </table>
                <form [formGroup]="invoiceNotaForm" (ngSubmit)="createNota()">
                  <div class="form-group required">
                    <label for="monto" class="form-label ms-2">Cantidad</label>
                    <input type="number" class="form-control form-control-lg" formControlName="monto">
                  </div>
                  <div class="form-group required mt-4">
                    <label for="metodoPago" class="form-label ms-2">Método de pago</label>
                    <select class="form-select form-select-lg rounded-4" formControlName="metodoPago">
                      <option value="" selected>Seleccionar...</option>
                      <option *ngFor="let metodo of metodoPago" [value]="metodo.id">{{metodo.metodo}}</option>
                    </select>
                    <div *ngIf="ufc['metodoPago'].touched && ufc['metodoPago'].invalid">
                      <div *ngIf="ufc['metodoPago'].errors?.['required']" class="text-danger text-sm">El método de pago es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mt-3">
                    <label for="formaPago" class="form-label ms-2">Tipo de pago</label>
                    <select class="form-select form-select-lg rounded-4" formControlName="formaPago">
                      <option value="" selected>Seleccionar...</option>
                      <option *ngFor="let tipo of tipoPago" [value]="tipo.id">{{tipo.tipo}}</option>
                    </select>
                    <div *ngIf="ufc['formaPago'].touched && ufc['formaPago'].invalid">
                      <div *ngIf="ufc['formaPago'].errors?.['required']" class="text-danger text-sm">La forma de pago es requerido.</div>
                    </div>
                  </div>   
                  <div class="form-group required mt-3">
                    <label for="payment_date">Fecha</label>
                    <input type="date" class="form-control form-control-lg" formControlName="payment_date">
                  </div>
                  <div class="form-group text-end mt-4">
                    <button type="submit" class="btn btn-outline-success rounded-5" [disabled]="loading">Generar nota de crédito</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalInvoiceNotaComponent implements OnInit {
  @Input() invoice: any;
  @Input() bill: any;
  @Input() conceptos: any;
  @Input() base: any;
  @Input() total: any;
  @ViewChild('modalInvoiceNota', { read: ElementRef, static: false }) modalInvoiceComplement !: ElementRef;

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  profile: any = {};
  loading: boolean = false;
  invoiceData: any = {};
  invoiceNotaForm: FormGroup = new FormGroup({});

  tipoPago: any = [
    { id: '01', tipo: 'Efectivo' },
    { id: '02', tipo: 'Cheque nominativo' },
    { id: '03', tipo: 'Transferencia electrónica de fondos' },    
    // { id: '99', tipo: 'Por definir' }
  ];
  metodoPago: any = [
    { id: 'PUE', metodo: 'Pago en una sola exhibición' },
  ];

  cpts: any = [];

  utilityData: any = {};

  parser: any;
  serie: any;
  folio: any;
  receptor: any;
  complemento: any;
  impuestos: any;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
  }

  ngOnInit(): void {
    this.buildInvoiceNotaForm();
  }

  get ufc() { return this.invoiceNotaForm.controls; }

  buildInvoiceNotaForm() {
    this.invoiceNotaForm = this.fb.group({
      monto: ['', Validators.required],
      metodoPago: ['', Validators.required],
      formaPago: ['', Validators.required],
      payment_date: ['', [Validators.required]],
    });

    this.store.getProfile(this.profile.profile.utility_id).subscribe(async (data: any) => {
      this.utilityData = data;
        let imageUrl = `${this.apiUrl}/storage/${data.logo.blob.key.substring(0,2)}/${data.logo.blob.key.substring(2,4)}/${data.logo.blob.key}`;
          this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
            this.utilityData.logo = "data:image/jpeg;base64," + result.split(',')[1];
          });
        console.log('utilityData  => ', this.utilityData);
    });
  }

  createNota() {
    this.loading = true;
    let ctl = this;
    if (this.invoiceNotaForm.invalid) {
      this.invoiceNotaForm.markAllAsTouched();
      this.toastr.error('Por favor, completa el formulario.');
      this.loading = false;
      return;
    }

    // console.log('INVOICE ==> ', this.invoice);
    this.parser = new xml2js.Parser({
      trim: true,            
      explicitArray: true,            
    });
    this.parser.parseString(this.invoice.cfdixml, function (err: any, result: any) {
      ctl.serie = result['cfdi:Comprobante']['$']['Serie'];
      ctl.folio = result['cfdi:Comprobante']['$']['Folio'];
      ctl.receptor = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$'];
      ctl.complemento = result['cfdi:Comprobante']['cfdi:Complemento'][0]['tfd:TimbreFiscalDigital'][0]['$'];
      ctl.conceptos = result['cfdi:Comprobante']['cfdi:Conceptos'][0]['cfdi:Concepto'];            
      ctl.impuestos = result['cfdi:Comprobante']['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'];
      ctl.base = Number(ctl.impuestos['Base']);
      ctl.total = Number(ctl.impuestos['Base']) + Number(ctl.impuestos['Importe']);
    });
    

    this.cpts = [
      {
        Cantidad: '1',
        CodigoUnidad: 'ACT',
        CodigoProducto: '84111506',
        Producto: 'Servicios de facturación',
        PrecioUnitario: this.invoiceNotaForm.value.monto,
        Importe: this.invoiceNotaForm.value.monto,
        ObjetoDeImpuesto: '02',
        Impuestos: [
          {
            TipoImpuesto: '1',
            Impuesto: '2',
            Factor: '1',
            Base: this.invoiceNotaForm.value.monto,
            Tasa: '0.160000',
            ImpuestoImporte: this.invoiceNotaForm.value.monto*0.16,
          }
        ]
      }
    ];

    console.log('receptor => ', this.receptor);

    let payload: any = {
      Encabezado: {
        "CFDIsRelacionados": this.invoice.uuid,
        "Fecha": this.invoiceNotaForm.value.payment_date,
        "Serie": 'NC',
        "Folio": this.invoice.folio,
        "MetodoPago": "PUE",
        "FormaPago": this.invoiceNotaForm.value.formaPago,
        "Moneda": "MXN",
        "LugarExpedicion": "26015",
        "SubTotal": this.invoiceNotaForm.value.monto,
        "Total": this.invoiceNotaForm.value.monto*1.16,
        "Receptor": {
          "RFC": this.receptor['Rfc'],
            "NombreRazonSocial": this.receptor['Nombre'],
            "UsoCFDI": 'G02',
            "RegimenFiscal": this.receptor['RegimenFiscalReceptor'],
            "Direccion": {
              "Calle": "",
              "NumeroExterior": "",
              "NumeroInterior": "",
              "Colonia": "",
              "Localidad": "",
              "Municipio": "",
              "Estado": "",
              "Pais": "",
              "CodigoPostal": this.receptor['DomicilioFiscalReceptor']
            }
        }
      },
      Conceptos: this.cpts,
      complemento: {
        serie: 'NC',
        folio: this.bill.id,
        related_invoice: this.invoice.id,
      },
      profile: this.profile.profile,
    }

    // console.log('PAYLOAD => ', payload);
    this.store.createInvoiceNotaCredito(payload).subscribe({
      next: (res: any) => {
        console.log(res);
        this.invoiceData = res;
        this.toastr.success('Complemento generado correctamente.', 'Éxito');
        this.loading = false;
        this.download();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al generar el complemento.', 'Error');
        this.loading = false;
      }
    });
  }

  download() {
    // console.log('download ====> ', this.bill);
    let dueDate = new Date(this.bill.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    this.store.getSubscriptions(this.bill.subscription_id).subscribe((data: any) => {
      this.store.getUnit(data.unit_id).subscribe(async (unitData: any) => {
        this.store.getBuilding(1).subscribe(async (data: any) => {
          const payload = {
            bill: this.bill,
            dueDate: dueDate,
            unitData: unitData,
            buildingData: data,
            invoiceData: this.invoiceData,
            utilityData: this.utilityData,
            conceptos: this.cpts
          };
  
          this.store.downloadNota(payload).subscribe({
            next: (data: any) => {
              const int8Array = new Uint8Array(data.result.data);
              const blob = new Blob([int8Array], { type: 'application/pdf' });
  
              var fileURL = window.URL.createObjectURL(blob);
              var link=document.createElement('a');

              link.href = fileURL;
              link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
              link.click();

              const blob2 = new Blob([this.invoiceData.cfdixml], { type: 'application/xml' });

              var fileURL2 = window.URL.createObjectURL(blob2);
              var link2=document.createElement('a');

              link2.href = fileURL2;
              link2.download = `${this.invoiceData.serie}_${this.invoiceData.folio}.xml`;
              link2.click();

              this.store.updateInvoice(this.invoiceData.id, { pdf: data.filename }).subscribe((data: any) => {
                console.log('updateInvoice => ', data);
                // window.location.reload();
              });
  
              return;
            }, error: (error: any) => {
              console.log('createReceipt ERROR => ', error);
            }
          });
        });
      });
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    console.log("BLOB => ", blob);
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
