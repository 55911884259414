import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-item-invoice-nota',
  template: `
    <div class="row">
      <div class="col-md-8">
        <h4 class="mb-0">Certificado #: {{invoice.noCertificado}}</h4>
        <p class="mb-0">Serie: {{invoice.serie}} Folio: {{invoice.folio}}</p>
        <p class="mb-0" class="mb-0 text-sm text-muted">Creado: {{invoice.created_at | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="col-md-4 clearfix">
        <div class="float-end">
          <!-- div class="badge rounded-pill me-3 px-3 py-2 border border-3" [ngClass]="invoice.status == 'active' ? 'border-success text-success' : 'border-warning text-warning'">
            <p class="mb-0 text-capitalize">{{invoice.status}}</p>
            href="{{receiptUrl}}/public/{{ invoice.pdf }}" target="_blank"
          </!-->

          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-secondary rounded-5 btn-sm dropdown-toggle py-1" id="dropdownInvoice" ngbDropdownToggle>
              <fa-icon [icon]="icons.faEllipsisVertical" class="m-1"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownInvoice">
              <button ngbDropdownItem (click)="selectInvoice(invoice)">
                <fa-icon [icon]="icons.faTimes"></fa-icon>
                Cancelar factura
              </button>
              <button ngbDropdownItem (click)="download()">
              <fa-icon [icon]="icons.faDownload"></fa-icon>
                Descargar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemInvoiceNotaComponent {
  @Input() invoice: any;
  @Input() profile: any;
  @Output() invoiceSelect: EventEmitter<any> = new EventEmitter<any>();

  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  apiUrl: string = environment.AWS_REST_WSPREFIX;

  parser: any;

  faDownload = faDownload;

  constructor(
    private store: SettlementsService,
    public icons: IconsService
  ) {    
  }

  selectInvoice(invoice: any) {
    this.invoiceSelect.emit(invoice);
  }

  download() {
    let ctl = this;
    // console.log('download ====> ', this.bill);

    // let dueDate = new Date(this.bill.due_date);
    // dueDate.setDate(dueDate.getDate());
    // dueDate.toISOString().substring(0, 10);

    this.store.getProfile(this.profile.id).subscribe(async (data: any) => {
      let utilityData = data;
        let imageUrl = `${this.apiUrl}/storage/${data.logo.blob.key.substring(0,2)}/${data.logo.blob.key.substring(2,4)}/${data.logo.blob.key}`;
          await this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
            utilityData.logo = "data:image/jpeg;base64," + result.split(',')[1];
          });
        console.log('utilityData  => ', utilityData);

        let cpts: any[] = [];
        let serie: any;
        let folio: any;
        let receptor: any;
        let complemento: any;
        let impuestos: any;
        let base: any;
        let total: any;

        this.parser = new xml2js.Parser({
          trim: true,            
          explicitArray: true,            
        });
        this.parser.parseString(this.invoice.cfdixml, function (err: any, result: any) {
          // console.log('STARTA =========');
          console.log('result => ', result);
          // console.log('receptor ==> ', result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']);
          serie = result['cfdi:Comprobante']['$']['Serie'];
          folio = result['cfdi:Comprobante']['$']['Folio'];
          receptor = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$'];
          complemento = result['cfdi:Comprobante']['cfdi:Complemento'][0]['tfd:TimbreFiscalDigital'][0]['$'];
          // ctl.conceptos = result['cfdi:Comprobante']['cfdi:Conceptos'][0]['cfdi:Concepto'];            
          impuestos = result['cfdi:Comprobante']['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'];
          base = Number(impuestos['Base']);
          total = Number(impuestos['Base']) + Number(impuestos['Importe']);
          // console.log("ctl.total => ", ctl.total);
          // console.log('err => ', err);


          result['cfdi:Comprobante']['cfdi:Conceptos'].forEach((element: any, index: number) => {
            console.log('CONCEPTOS ==============', element);
            element['cfdi:Concepto'].forEach((item: any) => {
              cpts.push({
                concepto_cantidad: item['$']['Cantidad'],
                concepto_descripcion: item['$']['Descripcion'],
                concepto_clave: item['$']['ClaveProdServ'],
                concepto_clave_unidad: item['$']['ClaveUnidad'],
                concepto_subtotal: item['$']['ValorUnitario'],
                concepto_total: item['$']['Importe'],
              });
            });
          });


        });

        const payload = {
          bill: {},
          dueDate: "",
          unitData: {},
          buildingData: {},
          invoiceData: this.invoice,
          utilityData: utilityData,
          conceptos: cpts
        };
  
        this.store.downloadNota(payload).subscribe({
          next: (data: any) => {
            const int8Array = new Uint8Array(data.result.data);
            const blob = new Blob([int8Array], { type: 'application/pdf' });
  
            var fileURL = window.URL.createObjectURL(blob);
            var link=document.createElement('a');
  
            link.href = fileURL;
            link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
            link.click();
  
            const blob2 = new Blob([this.invoice.cfdixml], { type: 'application/xml' });
  
            var fileURL2 = window.URL.createObjectURL(blob2);
            var link2=document.createElement('a');
  
            link2.href = fileURL2;
            link2.download = `${this.invoice.serie}_${this.invoice.folio}.xml`;
            link2.click();
  
            this.store.updateInvoice(this.invoice.id, { pdf: data.filename }).subscribe((data: any) => {
              console.log('updateInvoice => ', data);
              // window.location.reload();
            });
  
            return;
          }, error: (error: any) => {
            console.log('createReceipt ERROR => ', error);
          }
        });
    });


      
        
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    console.log("BLOB => ", blob);
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
