import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { faEdit, faPlus, faTrashAlt, faSave, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { DateService } from 'src/app/shared/services/date.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-create-global',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Factura global</h1>
            <p>Genera la factura global del periodo.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/payments/receipts">Generar recibos</!-->
            <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card rounded-4 shadow mb-4">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="mb-4">Configuración</h3>
                    <form [formGroup]="configForm" (ngSubmit)="load()">
                      <div class="form-group required mb-3">
                        <label for="periodicidad" class="form-label ms-2">Periodicidad</label>
                        <select class="form-select form-select-lg" formControlName="periodicidad">
                          <option selected>Periodicidad</option>
                          <option *ngFor="let periodo of periodicidad">{{periodo.clave}} - {{periodo.name}}</option>
                        </select>
                      </div>
                      <div class="form-group required mb-3">
                        <label for="periodicidad" class="form-label ms-2">Meses</label>
                        <select class="form-select form-select-lg" formControlName="mes">
                          <option selected>Mes</option>
                          <option *ngFor="let mes of meses" [value]="mes.clave">{{mes.clave}} - {{mes.name}}</option>
                        </select>
                        <!-- input type="text" class="form-control form-control-lg" formControlName="meses" -->
                      </div>
                      <div class="form-group required mb-3">
                        <label for="periodicidad" class="form-label ms-2">Año</label>
                        <input type="text" class="form-control form-control-lg" formControlName="anio">
                      </div>
                      <div class="form-group required mb-3">
                        <label for="settlement" class="form-label ms-2">Condominio</label>
                        <select class="form-select form-select-lg" formControlName="settlement_id">
                          <option selected>Condominio</option>
                          <option *ngFor="let settlement of settlements" [value]="settlement.id">{{settlement.name}}</option>
                        </select>
                      </div>
                      <div class="form-group required text-end mt-4">
                        <button type="submit" class="btn btn-outline-primary btn-lg rounded-5" [disabled]="searching">Cargar recibos</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="card rounded-4 shadow mb-3">
              <div class="card-body text-dark mb-3 p-4">
              <form [formGroup]="invoiceForm" (ngSubmit)="submit()">
                <h3 class="mb-4">Datos</h3>
                <div class="col-md-12">
                  <div class="form-group required mb-4 mt-3 d-none">
                    <label for="rfc" class="form-label ms-2">RFC</label>
                    <input type="text" class="form-control form-control-lg" formControlName="rfc">
                    <div *ngIf="ufc['rfc'].touched && ufc['rfc'].invalid">
                      <div *ngIf="ufc['rfc'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4 mt-3 d-none">
                    <label for="razon_social" class="form-label ms-2">Razón social</label>
                    <input type="text" class="form-control form-control-lg" formControlName="razon_social">
                    <div *ngIf="ufc['razon_social'].touched && ufc['razon_social'].invalid">
                      <div *ngIf="ufc['razon_social'].errors?.['required']" class="text-danger text-sm">La razón social es requerida.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4 mt-3 d-none">
                    <label for="zip_code" class="form-label ms-2">Código Postal</label>
                    <input type="text" class="form-control form-control-lg" formControlName="zip_code">
                    <div *ngIf="ufc['zip_code'].touched && ufc['zip_code'].invalid">
                      <div *ngIf="ufc['zip_code'].errors?.['required']" class="text-danger text-sm">El código postal es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4 mt-3 d-none">
                    <label for="regimen" class="form-label ms-2">Régimen fiscal</label>
                    <select class="form-select form-select-lg" formControlName="regimen">
                      <option *ngFor="let regimen of regimenes" [value]="regimen.id">{{regimen.id}} - {{regimen.name}}</option>
                    </select>
                    <div *ngIf="ufc['regimen'].touched && ufc['regimen'].invalid">
                      <div *ngIf="ufc['regimen'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4 mt-3 d-none">
                    <label for="uso_cfdi" class="form-label ms-2">Uso CFDI</label>
                    <select class="form-select form-select-lg" formControlName="uso_cfdi">
                      <option *ngFor="let uso of usosCFDI" [value]="uso.id">{{uso.id}} - {{uso.name}}</option>
                    </select>
                    <div *ngIf="ufc['uso_cfdi'].touched && ufc['uso_cfdi'].invalid">
                      <div *ngIf="ufc['uso_cfdi'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <table class="table table-sm">
                      <tr>
                        <th>Subtotal</th>
                        <td class="text-end">{{invoiceSubtotal | currency}}</td>
                      </tr>
                      <tr>
                        <th>IVA</th>
                        <td class="text-end">{{invoiceTaxes | currency}}</td>
                      </tr>
                      <tr>
                        <th>Total</th>
                        <td class="text-end">{{invoiceTotal | currency }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="form-group mb-0 mt-5 text-end">
                    <button type="button" class="btn btn-link me-2" routerLink="/invoices">Cancelar</button>
                    <button type="submit" class="btn btn-success btn-lg rounded-5 px-4" [disabled]="loading">
                      <fa-icon [icon]="faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
                      <fa-icon [icon]="faSave" class="me-2" *ngIf="!loading"></fa-icon>
                      Generar factura
                    </button>
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card rounded-4 shadow mb-3">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <h3>Recibos no facturados</h3>
                <div class="list-group" *ngIf="bills.length>0">
                  <li class="list-group-item" *ngFor="let bill of bills">                    
                    <app-item-bill-invoice 
                      (addBill)="updateBills($event)" 
                      [bill]="bill"                      
                      ></app-item-bill-invoice>
                  </li>
                </div>
                <div *ngIf="bills.length==0">
                  <div class="list-group-item text-center py-5">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceCreateGlobalComponent implements OnInit {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  bills: any = [];
  settlements: any = [];

  loading: boolean = false;
  searching: boolean = false;

  configForm: FormGroup = new FormGroup({});
  invoiceForm: FormGroup = new FormGroup({});

  serie: string = 'FC';
  formapago: string = '01';
  folio: number = 0;

  billList: any = [];

  invoiceSubtotal: number = 0;
  invoiceTaxes: number = 0;
  invoiceTotal: number = 0;

  utilityData: any = {};
  profile: any = {};


  faCircleNotch = faCircleNotch;
  faSave = faSave;

  periodicidad: any = [
    { id: 1, clave: '01', name: 'Diario' },
    { id: 2, clave: '02', name: 'Semanal' },
    { id: 3, clave: '03', name: 'Quincenal' },
    { id: 4, clave: '04', name: 'Mensual' },
    { id: 5, clave: '05', name: 'Bimestral' },
  ];

  meses: any = [
    { id: 1, clave: '01', name: 'Enero' },
    { id: 2, clave: '02', name: 'Febrero' },
    { id: 3, clave: '03', name: 'Marzo' },
    { id: 4, clave: '04', name: 'Abril' },
    { id: 5, clave: '05', name: 'Mayo' },
    { id: 6, clave: '06', name: 'Junio' },
    { id: 7 , clave: '07', name: 'Julio' },
    { id: 8, clave: '08', name: 'Agosto' },
    { id: 9, clave: '09', name: 'Septiembre' },
    { id: 10, clave: '10', name: 'Octubre' },
    { id: 11, clave: '11', name: 'Noviembre' },
    { id: 12, clave: '12', name: 'Diciembre' },
  ]

  regimenes: any = [
    { id: 601, name: 'General de Ley Personas Morales' },
    { id: 603, name: 'Personas Morales con Fines no Lucrativos' },
    { id: 605, name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { id: 606, name: 'Arrendamiento' },
    { id: 607, name: 'Régimen de Enajenación o Adquisición de Bienes' },
    { id: 608, name: 'Demás ingresos' },
    { id: 609, name: 'Consolidación' },
    { id: 610, name: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
    { id: 611, name: 'Ingresos por Dividendos (socios y accionistas)' },
    { id: 612, name: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { id: 614, name: 'Ingresos por intereses' },
    { id: 615, name: 'Régimen de los ingresos por obtención de premios' },
    { id: 616, name: 'Sin obligaciones fiscales' },
    { id: 620, name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { id: 621, name: 'Incorporación Fiscal' },
    { id: 622, name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { id: 623, name: 'Opcional para Grupos de Sociedades' },
    { id: 624, name: 'Coordinados' },
    { id: 625, name: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
    { id: 626, name: 'Régimen Simplificado de Confianza' },
    { id: 628, name: 'Hidrocarburos' },
    { id: 629, name: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
    { id: 630, name: 'Enajenación de acciones en bolsa de valores' }
  ];

  usosCFDI: any = [
    { id: 'G01', name: 'Adquisición de mercancías.' },
    { id: 'G02', name: 'Devoluciones, descuentos o bonificaciones.' },
    { id: 'G03', name: 'Gastos en general.' },
    { id: 'I01', name: 'Construcciones.' },
    { id: 'I02', name: 'Mobiliario y equipo de oficina por inversiones.' },
    { id: 'I03', name: 'Equipo de transporte.' },
    { id: 'I04', name: 'Equipo de computo y accesorios.' },
    { id: 'I05', name: 'Dados, troqueles, moldes, matrices y herramental.' },
    { id: 'I06', name: 'Comunicaciones telefónicas.' },
    { id: 'I07', name: 'Comunicaciones satelitales.' },
    { id: 'I08', name: 'Otra maquinaria y equipo.' },
    { id: 'D01', name: 'Honorarios médicos, dentales y gastos hospitalarios.' },
    { id: 'D02', name: 'Gastos médicos por incapacidad o discapacidad.' },
    { id: 'D03', name: 'Gastos funerales.' },
    { id: 'D04', name: 'Donativos.' },
    { id: 'D05', name: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
    { id: 'D06', name: 'Aportaciones voluntarias al SAR.' },
    { id: 'D07', name: 'Primas por seguros de gastos médicos.' },
    { id: 'D08', name: 'Gastos de transportación escolar obligatoria.' },
    { id: 'D09', name: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
    { id: 'D10', name: 'Pagos por servicios educativos (colegiaturas).' },
    { id: 'S01', name: 'Sin efectos fiscales.' },
    { id: 'CP01', name: 'Pagos' },
    { id: 'CN01', name: 'Nómina' }
  ];
  
  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dateService: DateService,
    private router: Router,
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!).profile;
  }

  ngOnInit(): void {    
    this.initForm();
    this.buildForm();
    this.loadSettlements();

    this.store.getProfile(this.profile.utility_id).subscribe((data: any) => {
      this.utilityData = data;
        let imageUrl = `${this.apiUrl}/storage/${data.logo.blob.key.substring(0,2)}/${data.logo.blob.key.substring(2,4)}/${data.logo.blob.key}`;
          this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
            this.utilityData.logo = "data:image/jpeg;base64," + result.split(',')[1];
          });
        console.log('utilityData  => ', this.utilityData);
    });
  }

  initForm(): void {
    this.invoiceForm = this.fb.group({
      rfc: ['XAXX010101000'],
      razon_social: ['PUBLICO EN GENERAL'],      
      zip_code: ['06100'],
      regimen: ['616'],
      formaPago: [''],
      metodoPago: [''],
      uso_cfdi: ['S01'],
    });
  }

  loadSettlements() {
    this.store.getSettlements({
      per_page: 1000,
      page: 1,
      // tipo: 0,
      utility_id: this.profile.utility_id
    }).subscribe((settlements: any) => {
      console.log("settlements => ", settlements);
      this.settlements = settlements.data;
    });
  }

  buildForm(): void {
    this.configForm = this.fb.group({
      periodicidad: ['', Validators.required],
      mes: ['', Validators.required],
      anio: ['2024', Validators.required],
      settlement_id: ['', Validators.required],
    });
  }

  get ufc() {
    return this.invoiceForm.controls;
  }

  updateBills(bill: any) {
    // console.log("bill => ", bill);
    let price = bill.consumo_periodo + bill.admin_price;
    this.billList.push({
      id: bill.id,
      concepto: bill.unit_name,
      sub_total: price,
      taxes: price*0.16
    });
    // this.billList.push(bill);
    this.invoiceSubtotal += price; 
    this.invoiceTaxes += price*0.16;
    this.invoiceTotal += price*1.16;
  }

  load() {
    this.searching = true;
    this.invoiceSubtotal = 0;
    this.invoiceTaxes = 0;
    this.invoiceTotal = 0;
    this.billList = [];
    // console.log("configForm => ", this.configForm.value);
    if (this.configForm.invalid) {
      this.configForm.markAllAsTouched();
      this.toastr.error('Por favor, completa los datos del formulario.', 'Error');
      this.searching = false;
      return;
    }

    this.store.getInvoiceGlobalLast({ profile_id: this.profile.id, tipo: 2}).subscribe((data: any) => {
      console.log('getInvoiceGlobalLast => ', data);
      this.folio = Number(data.folio) + 1;
      console.log('folio => ', this.folio); 
    })

    // console.log("invoiceForm => ", parseInt(this.configForm.value.anio), parseInt(this.configForm.value.mes));
    let periodos = this.dateService.getStartAndEndDateOfMonth(
      parseInt(this.configForm.value.anio), parseInt(this.configForm.value.mes));

    this.store.getSettlementInvoices(
      this.configForm.value.settlement_id, {
        periodo_since: periodos.start_date,
        periodo_to: periodos.end_date,
      })
      .subscribe((bills: any) => {
        console.log(bills);
        let invoiceable = bills.data.filter((bill: any) => bill.adv == false);
        console.log("invoiceable => ", invoiceable);
        this.bills = bills.data;
        this.searching = false;
    });
  }

  submit() {

    this.loading = true;

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString();

    let encabezados: any = {
      CFDIsRelacionados: "",
      TipoRelacion: "04",
      Receptor:{
        RFC: this.invoiceForm.value.rfc,
        NombreRazonSocial: this.invoiceForm.value.razon_social,
        UsoCFDI: this.invoiceForm.value.uso_cfdi,
        RegimenFiscal: this.invoiceForm.value.regimen,
        Direccion: {
          Calle: "",
          NumeroExterior: "",
          NumeroInterior: "",
          Colonia: "",
          Localidad: "",
          Municipio: "",
          Estado: "",
          Pais: "",
          CodigoPostal: this.invoiceForm.value.zip_code
        },        
      },
      InformacionFacturaGlobal:{
        Periodicidad: "02",
        Meses: "09",
        Año: this.configForm.value.anio,
      },
      Fecha: localISOTime.slice(0,19),
      Serie: this.serie.toString(),
      Folio: this.folio.toString(), //this.bill.id.toString(),
      MetodoPago:"PUE",
      FormaPago: this.formapago,
      Moneda: "MXN",
      LugarExpedicion: this.invoiceForm.value.zip_code,
      SubTotal: '', // this.billForm.value.sub_total.toFixed(2),
      Total: '', //this.billForm.value.total.toFixed(2),
    };

    let complemento: any = {
      formaPago: this.formapago,
      serie: this.serie.toString(),
      folio: this.folio.toString(),
      usoCFDI: this.invoiceForm.value.uso_cfdi,
      metodoPago: "PUE",
    };

    let payload: any = {
      profile: this.profile,
      Encabezado: encabezados,
      conceptos: this.billList,
      complemento: complemento,
      settlement_id: this.configForm.value.settlement_id,
    };

    console.log('payload ===> ', payload);

    this.store.createInvoiceGlobal(payload).subscribe({
      next: (invoice: any) => {
        let invoicePayload: any = {
          invoiceData: invoice,
          conceptos: this.billList,
          utilityData: this.utilityData,
          settlementData: this.settlements.find((settlement: any) => settlement.id == this.configForm.value.settlement_id),
        }

        this.store.downloadGlobal(invoicePayload).subscribe({
          next: (data: any) => {
            const int8Array = new Uint8Array(data.result.data);
            const blob = new Blob([int8Array], { type: 'application/pdf' });

            let fileURL = window.URL.createObjectURL(blob);
            let link=document.createElement('a');

            link.href = fileURL;
            link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
            link.click();

            this.store.updateInvoice(invoice.id, { pdf: data.filename }).subscribe((data: any) => {
              console.log('updateInvoice => ', data);
              this.loading = false;
              this.router.navigate(['/invoices/globales']);

            });
          },
          error: (err: any) => {
            this.loading = false;
            this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
            console.log('downloadGlobal => ', err);
          }
        });
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
      }
    });

  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
