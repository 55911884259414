import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice-canceled',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-4">
        
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Facturas canceladas</h1>
            <p>Consulta las facturas canceladas.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar factura
            </!-->
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <app-filter-full
          [profile]="profile"
          [toDate]="toDate"
          [fromDate]="fromDate"
          [payload]="payFilterPrefs"
          (billsSettlement)="loadInvoicesBySettlement($event)"
          (billsBuilding)="loadInvoicesByBuilding($event)"
          (billsFloor)="loadInvoicesByFloor($event)"
          (billsUnit)="loadInvoicesByUnit($event)"
          (billsSort)="loadInvoicesSort($event)"
          (billsOrder)="loadInvoicesOrder($event)"
          (dateRange)="updateDate($event)"
          (clear)="clearFilter()"
        ></app-filter-full>
        
            <div class="row mb-4">
              <div class="col-md-7">
                <!-- h2 class="mb-0">Habitacionales</!-->
              </div>
              <!-- div class="col-md-5">
                <form [formGroup]="searchBox" (ngSubmit)="searchInvoices($event)">
                  <input class="form-control form-control-lg" placeholder="Buscar..." formControlName="search">
                </form>
              </div -->
              <div class="col-md-12 mt-5 pb-3">
                <div class="list-group rounded-4 shadow" *ngIf="invoices.length>0">
                  <div class="list-group-item p-0 overflow-hidden" *ngFor="let invoice of invoices | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                    <app-item-invoice-canceled
                      [invoice]="invoice"
                      [profile]="profile"
                      [permissions]="permissions"
                    ></app-item-invoice-canceled>                    
                  </div>
                </div>
                <div class="list-group rounded-4 shadow" *ngIf="invoices.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-4" *ngIf="invoices.length>0">
                <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"                  
                ></pagination-controls>
              </div>
            </div>
          
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceCanceledComponent {
  loading: boolean = false;
  invoices: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};

  searchBox: FormGroup = new FormGroup({});

  payload: any = {
    page: 1,
    per_page: 50,
    tipo: 4,
    created_at_since: '',
    created_at_to: '',
    sort: 'created_at',
    order: 'DESC'
  };

  p: number = 1;

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  payFilterPrefs: any = {};

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService,
    public images: ImageService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);
    //
    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;

    this.payFilterPrefs = localStorage.getItem('payFilterPrefs') != null ? JSON.parse(localStorage.getItem('payFilterPrefs')!) : this.payload;
    this.payFilterPrefs.tipo = 4;
    this.payFilterPrefs.profile_id = this.profile.id;
    this.p = this.payFilterPrefs.page;

    this.loadInvoices(this.payFilterPrefs);
  }

  ngOnInit(): void {
    
    this.initForm();
    this.loadLogo();
    this.breadcrumbService.set('@creditNote', 'Facturas canceladas');
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payFilterPrefs.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payFilterPrefs.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadInvoices(this.payFilterPrefs);
    }
  }

  async loadLogo() {
    const img = this.images.buildURL(this.profile.logo);
    const imx: any = await this.images.getBase64ImageFromUrl(img);
    this.profile.logo = `${imx.split(',')[1]}`;
  }

  loadInvoices(payload: any) {
    this.loading = true;
    this.store.getInvoicesQueryCanceled(payload).subscribe({
      next: (invoices: any) => {
        console.log('CANCLADAS: ', invoices.data);
        this.invoices = invoices.data;
        this.meta = invoices.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.invoices = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las facturas.', 'Error');
        }
      }
    });
  }

  loadInvoicesBySettlement(settlement_id: any) {
    this.payFilterPrefs.settlement_id = settlement_id;    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    console.log('PAYFILTERPREFS', this.payFilterPrefs);

    this.loadInvoices(this.payFilterPrefs);
  }

  loadInvoicesByBuilding(building_id: any) {
    this.payFilterPrefs.building_id = building_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadInvoices(this.payFilterPrefs);
  }

  loadInvoicesByFloor(floor_id: any) {
    this.payFilterPrefs.floor_id = floor_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.unit_id;

    this.loadInvoices(this.payFilterPrefs);
  }

  loadInvoicesByUnit(unit_id: any) {
    this.payFilterPrefs.unit_id = unit_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    this.loadInvoices(this.payFilterPrefs);
  }

  loadInvoicesSort(event: any) {
    this.payFilterPrefs.sort = event;
    this.loadInvoices(this.payFilterPrefs);
  }

  loadInvoicesOrder(event: any) {
    this.payFilterPrefs.order = event;
    this.loadInvoices(this.payFilterPrefs);
  }

  loadInvoicesPerPage(event: any) {
    this.payFilterPrefs.per_page = event;
    this.loadInvoices(this.payFilterPrefs);
  }

  clearFilter() {
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.settlement_id;
    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadInvoices(this.payFilterPrefs);
  }

  pageChanged(event: any) {
    this.payFilterPrefs.page = event;
    this.loadInvoices(this.payFilterPrefs);
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  tag(status: any) {
    if (status === 'active') {
      return 'Activo';
    } else {
      return 'Inactivo';
    }
  }
}
